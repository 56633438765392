import React from 'react'
import classnames from 'classnames'
// import ReactPlayer from 'react-player'
// import { isMobile } from 'react-device-detect'
// import Markdown from 'markdown-to-jsx'

import appTvLeft from './assets/Product_TVcurved1.png'
// import tv from './assets/TVWhiteBgThinMargins.png'
// import video from './assets/videoMayaCentaurus.mp4'
import appTvRight from './assets/Product_Tvcurved2.png'
import playstoreLogo from './assets/Product_Logo_PlayStore.png'

import styles from './index.module.css'
import Container from '../../../Container'

export default () => {
  return (
    <section className={styles.operator}>
      <Container>
        <div className={classnames(styles.row, styles.rowInverse)}>
          <div className={styles.image}>
            <img src={appTvLeft} alt="screen tv left" />
          </div>
          <div className={styles.content}>
            <h3 className={styles.title}>
              Android TV Operator Tier <br /> Custom Launcher ...
            </h3>
            <p>
              iFeelsmart provides white-labeled customizable Operator Tier
              Launcher, fully compliant with Google's requirements while giving
              to Operators full control of UI. Within a single unified launcher,
              consumers can easily access both operator's content ans Android TV
              Apps
            </p>
          </div>
        </div>
        <figure className={styles.playstoreLogo}>
          <img src={playstoreLogo} alt="Google Playstore Logo" />
        </figure>
        {/* {!isMobile && (
        <div className={styles.flexContainer}>
          <div className={styles.playerWrapper}>
            <img className={styles.tv} src={tv} alt="" />
            <div className={styles.playerContainer}>
              <ReactPlayer
                url={video}
                className={styles.reactPlayer}
                loop
                playing
                width="100%"
                height="auto"
                controls
                playsinline
                muted
              />
            </div>
          </div>
        </div>
      )} */}
        <div className={styles.row}>
          <div className={styles.content}>
            <h3 className={styles.title}>
              ...more than just
              <br />
              applications on TV
            </h3>
            <p>
              With Android TV, consumers benefit from a large application
              ecosystem coming from the Google Play Store. For Operators and
              Service Providers, iFeelsmart's EMI Cloud UX Manager provides a
              unique value proposition by allowing them to manage intermediation
              between linear television and new content services proposed by
              Android TV applications.
            </p>
          </div>
          <div className={styles.image}>
            <img src={appTvRight} alt="screen tv right" />
          </div>
        </div>
      </Container>
    </section>
  )
}

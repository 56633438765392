import React from 'react'
import classnames from 'classnames'
import 'animate.css'
// import ScrollAnimation from 'react-animate-on-scroll'
import Markdown from 'markdown-to-jsx'

// import ghome from './assets/googleAssistant.svg'
import secondBg from './assets/Product_3Screens.png'

import androidTVAndTV from './assets/androidTV+TV.png'
import tvOSAndTV from './assets/tvOS+TV.png'
import fireTVAndTV from './assets/fireTV+TV.png'

import appleTv from './assets/Product_AppleTV.png'
import androidTvLogo from './assets/Product_AndroidTV_Logo.png'
import androidTv from './assets/Product_AndroidTV.png'
import rokuLogo from './assets/Product_Roku_Logo.png'
import webOsLogo from './assets/Product_WebOS_Logo.png'
import tizenLogo from './assets/Product_Tizen_Logo.png'
import styles from './index.module.css'

import Container from '../../Container'
import Operator from './Operator'

const Tv = ({ data }) => {
  return (
    <section className={styles.tv}>
      <div className={classnames(styles.zero, styles.container)}>
        <h2 className={styles.blockTitle}>
          Multiscreen <br />
          solutions
        </h2>
      </div>

      <div className={classnames(styles.first, styles.container)}>
        <Container>
          <div className={styles.blockText}>
            <h2 className={styles.blockTitle}>{data.tv_title_1}</h2>
            <div className={styles.blockContent}>
              <Markdown options={{ forceBlock: true }}>
                {data.tv_text_1}
              </Markdown>
            </div>
            <div className={styles.cards}>
              <div className={styles.card}>Linear TV</div>
              <div className={styles.card}>Video on Demand</div>
              <div className={styles.card}>Catch-up</div>
              <div className={styles.card}>DVR</div>
            </div>
          </div>
        </Container>
      </div>

      <div className={classnames(styles.second, styles.container)}>
        <Container>
          <div className={styles.blockText}>
            <h2 className={styles.blockTitle}>{data.tv_title_2}</h2>
            <div className={styles.blockContent}>
              <Markdown options={{ forceBlock: true }}>
                {data.tv_text_2}
              </Markdown>
            </div>
          </div>
          <div className={styles.hideOnDesktop}>
            <figure
              style={{
                padding: '1rem',
                margin: 0,
                paddingBottom: 0,
              }}
            >
              <img src={androidTVAndTV} alt="Android TV" />
            </figure>
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                paddingBottom: 0,
              }}
            >
              <figure
                style={{
                  margin: '1rem',
                }}
              >
                <img src={tvOSAndTV} alt="Apple TV" />
              </figure>
              <figure
                style={{
                  margin: '1rem',
                }}
              >
                <img src={fireTVAndTV} alt="Amazon Fire TV" />
              </figure>
            </div>
          </div>
          <div className={styles.hideSmallDevices}>
            <img
              src={secondBg}
              alt="3 screens products"
              className={styles.img}
            />
          </div>
          <div
            className={classnames(
              styles.devicesContainer,
              styles.hideSmallDevices
            )}
          >
            <img src={appleTv} alt="apple tv" />
            <img src={androidTvLogo} alt="android tv logo" />
            <img src={androidTv} alt="android tv" />
          </div>
          <div className={styles.devicesOs}>
            <figure>
              <img src={rokuLogo} alt="Roku logo" />
            </figure>
            <figure>
              <img src={webOsLogo} alt="webOS logo" />
            </figure>
            <figure>
              <img src={tizenLogo} alt="Tizen logo" />
            </figure>
          </div>
        </Container>
      </div>

      <Operator />

      {/* <div className={classnames(styles.third, styles.container)}>
        <div className={styles.blockText}>
          <h2 className={styles.blockTitle}>{data.tv_title_3}</h2>
        </div>
        <div className={classnames(styles.blockText, styles.rowLow)}>
          <div className={styles.blockContent}>
            <Markdown options={{ forceBlock: true }}>{data.tv_text_3}</Markdown>
          </div>
        </div>
        <div className={styles.ghomeContainer}>
          <div className={styles.ghomeLogoWrapper}>
            <div className={styles.ghomeLogo}>
              <ScrollAnimation
                animateOnce={true}
                animateIn="fadeInDown"
                delay={1000}
              >
                <img src={ghome} alt="google home logo" />
              </ScrollAnimation>
            </div>
            <ScrollAnimation animateOnce={true} animateIn="fadeInDown">
              <p className={styles.question}>"{data.google_question}"</p>
            </ScrollAnimation>
            <ScrollAnimation
              className={styles.answer}
              animateOnce={true}
              animateIn="fadeInDown"
              delay={2000}
            >
              <p>"{data.google_answer}"</p>
            </ScrollAnimation>
          </div>
        </div>
      </div> */}
    </section>
  )
}

export default Tv

import React from 'react'
import Markdown from 'markdown-to-jsx'

import styles from './index.module.css'

const Laptop = ({ data }) => {
  return (
    <section className={styles.laptop}>
      <div className={styles.container}>
        <h2>
          {data.laptop_title}
          <br />
          {data.laptop_subtitle}
        </h2>

        <div className={styles.browsers}>
          <svg
            className={styles.browser}
            enable-background="new 0 0 512 512"
            height="512px"
            id="Layer_1"
            viewBox="0 0 512 512"
          >
            <g>
              <path
                d="M182.411,256.486c0,37.638,30.512,68.152,68.147,68.152c37.633,0,68.149-30.515,68.149-68.152   c0-37.632-30.516-68.148-68.149-68.148C212.922,188.338,182.41,218.854,182.411,256.486z"
                fill="#414751"
              />
              <path
                d="M250.558,149.725c34.618,0,61.106,11.749,80.61,37.287l3.867-0.353l166.004-3.251   C469.779,77.625,371.988,0.271,256,0.271c-37.511,0-73.062,8.285-105.193,22.813l42.613,143.482   C209.966,156.022,229.5,149.725,250.558,149.725z"
                fill="#414751"
              />
              <path
                d="M508.098,215.152l-158.07,3c4.572,11.927,7.373,24.787,7.373,38.335c0,59.004-47.84,106.78-106.842,106.78   c-1.804,0-1.334-0.243-3.104-0.307l-0.661,0.99l-99.469,123.282c33,15.565,69.765,24.496,108.677,24.496   c141.244,0,255.705-114.544,255.705-255.755C511.705,242.01,510.244,228.462,508.098,215.152z"
                fill="#414751"
              />
              <path
                d="M143.778,256.487c0-26.002,7.711-48.915,23.107-67.451L122.128,38.433   C49.128,83.472,0.294,163.91,0.294,255.975c0,90.729,47.372,170.28,118.663,215.674l93.403-115.738   C172.269,340.499,143.778,301.991,143.778,256.487z"
                fill="#414751"
              />
            </g>
          </svg>

          <svg className={styles.browser} viewBox="9.7 16.5 280 266">
            <path d="M288.9 113.8c-1.4 3.5-2.5 7.1-3.9 10.6-1-14.7-3.4-29.3-8.1-43.2-3.2-8.6-6.9-17.2-13.3-23.9.9 8.2 3 16.2 2.8 24.5-1.8-6.3-3.9-12.7-7.7-18.1-8.2-12.7-21.5-21.3-35.7-25.9-3.7-1.2-7.6-1.9-10.9-3.9-20.4-11.9-44.2-17.8-67.8-17.2-30.7.4-60.9 12.5-83.4 33.3-3.5 3.3-6.7 6.9-10.2 10.3-6.1-7.1-9.2-16.1-9.6-25.4-5.5 4.7-8.7 11.5-11 18.2-3.2 9.4-4.9 19.4-3.9 29.3.1 2.2.7 4.6-.8 6.5-7.8 11.4-14.1 24.4-15.6 38.3 2.5-1.7 4.9-3.5 7.4-5.2-1 5-2.4 10-3.2 15.1-3.5 24.5 1 50 12.7 71.9 12.8 23.6 32.6 43.1 55.9 56.4 26.2 14.9 57.5 20.4 87.3 15.6 26-4.3 51.2-15.7 69.8-34.6 13.2-13.4 24.7-28.5 33-45.4 13.6-26.8 19.3-57.5 16.2-87.2m-48.7 38.4c-2.7-5.5-4.1-12-9.3-15.8 1.3 13.8 1.9 27.8-1.2 41.4-1.9 9.2-6 18.1-12.7 24.8 1.2-6.6 2.3-13.3 1.9-20-9.7 13.1-21.4 25.2-36.3 32.3-12.6 6.1-27.5 5.8-40.7 1.7-12-3.9-22.4-11.3-31.6-19.7h3.2c9 .6 18.1-.7 26.7-3.2 8.6-2.5 15-8.9 22.5-13.4 3.5-2.1 7.9-1.2 11.7-2.3 2.4-1.3 3.2-4.8 1-6.7-5.6-6.1-13.7-11-22.2-10-8.2 1.2-14.9 6.8-23 8.7-5.8 1-11.7-.8-16.8-3.4-7.3-3.7-12.8-9.9-18-16l-2.3-1.9c0-.9 0-1.9.1-2.8.5-2.5 1.9-4.7 3.1-7l1.4-2.8c8.1.6 16.1 1.9 24 3.9-.1-7.5-.6-16-5.2-22.2l1.6-1.2c5.1-4 9.8-8.7 15.5-11.8 2.3-1.2 4.4-2.8 6.1-4.7l.4-3.4h-.1c.1-1.3-.1-2.7-.1-4-2.8-.3-5.6-.5-8.5-.5l-7.8-.5c-.6-.4-1.2-.8-1.8-1.1-5.3-3.3-9.9-7.7-14.9-11.4l-2-1.8.3-2.7c1.9-12.5 11.1-22.3 20.7-29.8-6.8 1-13.5 2.9-19.6 6C99.9 54 94.4 58.7 89 63.3c-10.9-2.8-22.6-1.1-33 2.9-5.1 2.3-9.7 5.7-14.7 8.3 3.2-3.2 6.9-5.7 10.5-8.5 3.2-2.4 5.6-5.7 8.4-8.5 20.8-21.4 49.9-34.3 79.6-35.8 19.7-1 40 2.1 57.8 10.8-4.8-.2-9.5-.5-14.3-.4 10.7 3.2 23.2 6.6 29.2 17-5.5.2-11.1.3-16.4 2.1 15.3 5.9 30.7 13.2 42.1 25.4 6.8 7.3 11.5 16.8 11.3 27-3.6-2.7-7.3-5.2-11.3-7.2 4.3 18.1 7 37.4 2 55.8" />
          </svg>

          <svg className={styles.browser} viewBox="0 0 1792 1792">
            <path d="M949 893q0 26-16.5 45t-41.5 19q-26 0-45-16.5t-19-41.5q0-26 17-45t42-19 44 16.5 19 41.5zm15 58l350-581q-9 8-67.5 62.5t-125.5 116.5-136.5 127-117 110.5-50.5 51.5l-349 580q7-7 67-62t126-116.5 136-127 117-111 50-50.5zm647-55q0 201-104 371-3-2-17-11t-26.5-16.5-16.5-7.5q-13 0-13 13 0 10 59 44-74 112-184.5 190.5t-241.5 110.5l-16-67q-1-10-15-10-5 0-8 5.5t-2 9.5l16 68q-72 15-146 15-199 0-372-105 1-2 13-20.5t21.5-33.5 9.5-19q0-13-13-13-6 0-17 14.5t-22.5 34.5-13.5 23q-113-75-192-187.5t-110-244.5l69-15q10-3 10-15 0-5-5.5-8t-10.5-2l-68 15q-14-72-14-139 0-206 109-379 2 1 18.5 12t30 19 17.5 8q13 0 13-12 0-6-12.5-15.5t-32.5-21.5l-20-12q77-112 189-189t244-107l15 67q2 10 15 10 5 0 8-5.5t2-10.5l-15-66q71-13 134-13 204 0 379 109-39 56-39 65 0 13 12 13 11 0 48-64 111 75 187.5 186t107.5 241l-56 12q-10 2-10 16 0 5 5.5 8t9.5 2l57-13q14 72 14 140zm85 0q0-163-63.5-311t-170.5-255-255-170.5-311-63.5-311 63.5-255 170.5-170.5 255-63.5 311 63.5 311 170.5 255 255 170.5 311 63.5 311-63.5 255-170.5 170.5-255 63.5-311zm96 0q0 182-71 348t-191 286-286 191-348 71-348-71-286-191-191-286-71-348 71-348 191-286 286-191 348-71 348 71 286 191 191 286 71 348z" />
          </svg>

          <svg className={styles.browser} viewBox="0 0 1792 1792">
            <path d="M69 795h1q16-126 58.5-241.5t115-217 167.5-176 223.5-117.5 276.5-43q231 0 414 105.5t294 303.5q104 187 104 442v188h-1125q1 111 53.5 192.5t136.5 122.5 189.5 57 213 3 208-46.5 173.5-84.5v377q-92 55-229.5 92t-312.5 38-316-53q-189-73-311.5-249t-124.5-372q-3-242 111-412t325-268q-48 60-78 125.5t-46 159.5h635q8-77-8-140t-47-101.5-70.5-66.5-80.5-41-75-20.5-56-8.5l-22-1q-135 5-259.5 44.5t-223.5 104.5-176 140.5-138 163.5z" />
          </svg>
        </div>
      </div>

      <div className={styles.blockText}>
        <Markdown options={{ forceBlock: true }}>{data.laptop_text}</Markdown>
      </div>
    </section>
  )
}

export default Laptop

import React from 'react'
import classnames from 'classnames'
import Markdown from 'markdown-to-jsx'

import styles from './index.module.css'

const Tablet = ({ data }) => {
  return (
    <section className={styles.tablet}>
      <div className={classnames(styles.first, styles.container)}>
        <div className={styles.bg}></div>
        <div className={styles.blockText}>
          <h2>
            Best mobile <br />
            TV experience...
          </h2>
          {/* <h2>{data.tablet_title_1}</h2> */}
          <Markdown options={{ forceBlock: true }}>
            {data.tablet_text_1}
          </Markdown>
        </div>
      </div>

      <div className={classnames(styles.second, styles.container)}>
        <div className={styles.blockText}>
          <h2>...&nbsp;with advanced playback experience&nbsp;...</h2>
          {/* <h2>{data.tablet_title_2}</h2> */}
          <Markdown options={{ forceBlock: true }}>
            {data.tablet_text_2}
          </Markdown>
        </div>
      </div>
    </section>
  )
}

export default Tablet

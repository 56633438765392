import React from 'react'
import Layout from '../components/Layout'
import { graphql } from 'gatsby'
import classnames from 'classnames'
import { slides } from '../helpers'
import Dot from '../components/Dot'
import ScrollAnimation from 'react-animate-on-scroll'

import Tv from '../components/ProductsPage/Tv'
import InfoBanner from '../components/InfoBanner'
import Tablet from '../components/ProductsPage/Tablet'
import Smartphone from '../components/ProductsPage/Smartphone'
import Laptop from '../components/ProductsPage/Laptop'

import Phone from './assets/mobileNotifications.png'
import styles from './products.module.css'

// TODO: fix blink on Dot on click (?!)

class ProductsPage extends React.Component {
  wrapper0 = null
  wrapper1 = null
  wrapper2 = null
  wrapper3 = null

  state = {
    activeIndex: 0,
  }

  handleClickDot(dotIndex) {
    const anchor = this[`wrapper${dotIndex}`]
    // const scroll = new SmoothScroll()
    // this.scroll.init()
    const SmoothScroll = require('smooth-scroll')
    const scroll = new SmoothScroll()
    scroll.animateScroll(anchor)
    this.setState({
      activeIndex: dotIndex,
    })
  }

  getOffsetTop(elem) {
    let offsetTop = 0
    do {
      if (!isNaN(elem.offsetTop)) {
        offsetTop += elem.offsetTop
      }
    } while (elem === elem.offsetParent)
    return offsetTop
  }

  handleScroll = e => {
    // calculate half of the screen
    let scrollTop = window.scrollY + window.innerHeight / 2

    if (scrollTop > this.getOffsetTop(this.wrapper0)) {
      this.setState({
        activeIndex: 0,
      })
    }
    if (scrollTop > this.getOffsetTop(this.wrapper1)) {
      this.setState({
        activeIndex: 1,
      })
    }
    if (scrollTop > this.getOffsetTop(this.wrapper2)) {
      this.setState({
        activeIndex: 2,
      })
    }
    if (scrollTop > this.getOffsetTop(this.wrapper3)) {
      this.setState({
        activeIndex: 3,
      })
    }
  }

  componentDidMount() {
    document.addEventListener('scroll', this.handleScroll)
  }

  componentWillUnmount() {
    document.removeEventListener('scroll', this.handleScroll)
  }

  render() {
    const data = this.props.data.allStrapiDevicespages.edges[0].node
    return (
      <Layout className={styles.devicesPage} style={{ margin: 0, padding: 0 }}>
        {/* note add class when ListDevices comes to the top of the Footer */}

        <div className={styles.ListDevices}>
          {slides.map((slide, key) => {
            return (
              <div key={key} onClick={() => this.handleClickDot(key)}>
                <Dot
                  iconType={slide.iconType}
                  isActive={key === this.state.activeIndex}
                  iconSmall
                />
              </div>
            )
          })}
        </div>

        {/* <ListDevices rootStyle={styles.ListDevices} rootStyleDevice={styles.device} /> */}
        <div ref={component => (this.wrapper0 = component)}>
          <Tv data={data} />
        </div>
        <InfoBanner rootStyle={classnames(styles.infoBanner, styles.videoCast)}>
          <div>
            <svg fill="#FFFFFF" height="100" viewBox="0 0 24 24">
              <path d="M0 0h24v24H0z" fill="none" opacity=".1" />
              <path d="M0 0h24v24H0z" fill="none" />
              <path d="M21 3H3c-1.1 0-2 .9-2 2v3h2V5h18v14h-7v2h7c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2zM1 18v3h3c0-1.66-1.34-3-3-3zm0-4v2c2.76 0 5 2.24 5 5h2c0-3.87-3.13-7-7-7zm0-4v2c4.97 0 9 4.03 9 9h2c0-6.08-4.93-11-11-11z" />
            </svg>
          </div>
          <div className={styles.blockText}>
            <h3>{data.infobanner_title_1}</h3>
            <p>{data.infobanner_text_1}</p>
          </div>
          <div>
            <svg height="100" fill="#FFFFFF" viewBox="0 0 24 24">
              <defs>
                <path d="M0 0h24v24H0V0z" id="a" />
              </defs>
              <defs>
                <path d="M0 0h24v24H0V0z" id="c" />
              </defs>
              <clipPath id="b">
                <use overflow="visible" />
              </clipPath>
              <clipPath clip-path="url(#b)" id="d">
                <use overflow="visible" />
              </clipPath>
              <path
                clip-path="url(#d)"
                d="M6 22h12l-6-6zM21 3H3c-1.1 0-2 .9-2 2v12c0 1.1.9 2 2 2h4v-2H3V5h18v12h-4v2h4c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2z"
              />
            </svg>
          </div>
        </InfoBanner>
        <div ref={c => (this.wrapper1 = c)}>
          <Tablet data={data} />
        </div>
        <div ref={c => (this.wrapper2 = c)}>
          <Smartphone data={data} />
        </div>
        <InfoBanner
          rootStyle={classnames(styles.infoBanner, styles.notifications)}
        >
          <div className={styles.phoneWrapper}>
            <img src={Phone} alt="mobile" />
          </div>
          <div className={styles.blockText}>
            <h3>{data.infobanner_title_2}</h3>
            <p>{data.infobanner_text_2}</p>
          </div>
          <ScrollAnimation animateIn="shake">
            <svg className={styles.hide} viewBox="0 0 20 20">
              <g
                id="Page-1"
                stroke="none"
                stroke-width="1"
                fill="none"
                fill-rule="evenodd"
              >
                <g id="bell" fill="#FFFFFF" fill-rule="nonzero">
                  <path
                    d="M5.58,2.08 L4.15,0.65 C1.75,2.48 0.17,5.3 0.03,8.5 L2.03,8.5 C2.18,5.85 3.54,3.53 5.58,2.08 Z M17.97,8.5 L19.97,8.5 C19.82,5.3 18.24,2.48 15.85,0.65 L14.43,2.08 C16.45,3.53 17.82,5.85 17.97,8.5 Z M16,9 C16,5.93 14.36,3.36 11.5,2.68 L11.5,2 C11.5,1.17 10.83,0.5 10,0.5 C9.17,0.5 8.5,1.17 8.5,2 L8.5,2.68 C5.63,3.36 4,5.92 4,9 L4,14 L2,16 L2,17 L18,17 L18,16 L16,14 L16,9 Z M10,20 C10.14,20 10.27,19.99 10.4,19.96 C11.05,19.82 11.58,19.38 11.84,18.78 C11.94,18.54 11.99,18.28 11.99,18 L7.99,18 C8,19.1 8.89,20 10,20 Z"
                    id="Shape"
                  ></path>
                </g>
              </g>
            </svg>
          </ScrollAnimation>
        </InfoBanner>
        <div ref={c => (this.wrapper3 = c)}>
          <Laptop data={data} />
        </div>
      </Layout>
    )
  }
}

export default ProductsPage

export const devicesPageQuery = graphql`
  query DevicesQuery {
    allStrapiDevicespages {
      edges {
        node {
          id
          tv_title_1
          tv_text_1
          tv_title_2
          tv_text_2
          tv_title_3
          tv_text_3
          google_question
          google_answer
          infobanner_title_1
          infobanner_text_1
          tablet_title_1
          tablet_text_1
          tablet_title_2
          tablet_text_2
          smartphone_title
          smartphone_subtitle
          smartphone_text
          infobanner_title_2
          infobanner_text_2
          laptop_title
          laptop_subtitle
          laptop_text
        }
      }
    }
  }
`

import React from 'react'
import classnames from 'classnames'
import styles from './index.module.css'

class Dot extends React.Component {
  state = {
    isActive: false,
  }

  handleClick = () => {
    this.setState({
      isActive: !this.state.isActive,
    })
  }

  render() {
    const { rootStyle, iconType, iconSmall, isActive } = this.props

    return (
      <div
        className={classnames(
          styles.device,
          rootStyle,
          {
            [styles.isActive]: isActive,
          },
          { [styles.iconSmall]: iconSmall }
        )}
        onClick={this.handleClick}
      >
        <i
          className={classnames(
            // rootStyle,
            styles.icon,
            {
              [styles.iconTv]: iconType === 'tv',
              [styles.iconTablet]: iconType === 'tablet',
              [styles.iconMobile]: iconType === 'mobile',
              [styles.iconLaptop]: iconType === 'laptop',
            }
          )}
        ></i>
      </div>
    )
  }
}

export default Dot

module.exports = {
  slides: [
    { id: 0, iconType: 'tv', isActive: true },
    { id: 1, iconType: 'tablet', isActive: false },
    { id: 2, iconType: 'mobile', isActive: false },
    { id: 3, iconType: 'laptop', isActive: false },
  ],
  covers: [
    './assets/TvMockUP.jpg',
    './assets/TabletMockUp.jpg',
    './assets/MobileMockUp.jpg',
    './assets/DesktopMockup.jpg',
  ],
}
